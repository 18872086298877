import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取志愿者活动列表数据
  // 获取居民点单记录列表数据
  recordList: params => get('/residentTask/record/list', params),
  // 获取居民档案列表数据
  residentArchivesList: params => get('/residentArchives/list', params),
  // 获取志愿者管理列表数据
  volunteerInfoList: params => get('/volunteerInfo/list', params),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 根据id获取居民点单详情
   getRecordById: id => get(`/residentTask/record/getById?id=${id}`),
  // 获取点单类型
  getTypeTreeList:args => get('/residentTask/type/tree', args),
  // 分组管理/点单类型分页查询列表数据
  getTypeList: args => get('/residentTask/type/list', args),


  // // 根据id获取人口详情
  // getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // // 根据id获取走访信息详情
  // getInterviewInfoById: id => get(`/interviewInfo/getById?id=${id}`),
  // // 获取省的行政区划
  // getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // // 获取市级的行政区划
  // getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // // 获取区级的行政区划
  // getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // // 获取县级的行政区划
  // getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),
  // // 获取居民信息表格数据
  // getPersonTableData: args => get('/residentArchives/listAll', args),
  /**
   * 插入/修改
   */
  // 新增居民点单
  addRecord: args => post('/residentTask/record/add', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 根据id删除
  deleteRecordById: id => deletes(`/residentTask/record/deleteById?id=${id}`),
  // 批量删除
  deleteVolunteerActivityByIds: ids => deletes(`/volunteerActivity/ids?ids=${ids}`),
  // 编辑点单状态
  updateRecord: args => post('/residentTask/record/update', args),
  // 审核点单状态（拒绝 通过）
  auditRecord: args => post('/residentTask/record/audit', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
